import React, {useEffect, useState} from "react";
import Header from "../inspection/header/Header";
import {Redirect, useHistory} from "react-router-dom";
import Popup from "../components/popup/Popup";
import Api from "../util/Api";
import ValidationError from "../util/ValidationError";
import "./smartselect.scss"
import AppStorage from "../util/AppStorage";
import Icon from "../util/Icon";
import SmartSelectContent from "./smartselectcontent/SmartSelectContent";

function SmartSelect(props) {
    const history = useHistory();
    const [form] = useState(AppStorage.getInspectionForm());
    const smartSelectId = parseInt(props.match.params.id);
    const [smartSelectQuestion] = useState(getSmartSelectQuestion());

    const [isLoading, setIsLoading] = useState(true);
    const [queryDelay, setQueryDelay] = useState();

    const [smartSelectData, setSmartSelectData] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [unselectedItems, setUnselectedItems] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        return () => clearTimeout(queryDelay);
    });

    useEffect(() => {
        getSmartSelectItems()
    }, []);

    useEffect(() => {
        getSmartSelectItems(searchQuery);
    }, [searchQuery]);

    if (!form || !smartSelectQuestion || !smartSelectId) {
        return (
            <Redirect to={'/inspection'}/>
        );
    }
    const searchElement = <SearchView onChange={searchOnChange} defaultValue={searchQuery}/>;
    const contentElement = <ListView
        searchQuery={searchQuery}
        titlePlural={smartSelectQuestion.titlePlural}
        canCreate={smartSelectQuestion.smartselectCanAdd}
        selectedItems={selectedItems}
        isLoading={isLoading}
        onItemClick={valueClicked}
        unselectedItems={unselectedItems}
        onCreateClick={onCreateNewOptionClicked}/>;

    return (
        <div id="smart-select" className="main">
            <Header title={smartSelectQuestion.title} leftIcon="back-arrow" leftIconClick={goToPreviousPage}/>
            <div className="smart-select-list-wrapper">
                {searchElement}
                {contentElement}
            </div>
        </div>
    );

    function searchOnChange(event) {
        const query = event.target.value;
        setSearchQuery(query);
    }


    function getSmartSelectItems(query) {
        function validator(response) {
            if (!response.data || !response.data.id || !response.data.titlePlural || !response.data.titleSingular) {
                throw new ValidationError("Smart Select data validation error.");
            }
        }

        function onSuccess(response) {
            setIsLoading(false);

            smartSelectQuestion.titleSingular = response.data.titleSingular;
            smartSelectQuestion.titlePlural = response.data.titlePlural;
            AppStorage.setInspectionForm(form);

            createSmartSelectItems(response.data.items ? response.data.items : []);
            setSmartSelectData(response.data);
        }

        function onFailure(error) {
            setIsLoading(false);
            Popup.showDefaultRequestError(
                function () {
                    getSmartSelectItems(query);
                },
                function () {
                    goToPreviousPage();
                }
            )
        }

        setIsLoading(true);
        let config = {
            params: {
                q: query
            }
        };

        let url = '/form/smartselect/' + props.match.params.id + '/' + form.referenceId + '/options';


        if (queryDelay) clearTimeout(queryDelay);
        setQueryDelay(setTimeout(() => {
            Api.get(url, validator, onSuccess, onFailure, config)
        }, 300));
    }

    function createSmartSelectItems(smartSelectItems) {
        let currentlySelectedSmartSelectOptions = smartSelectQuestion.value ? smartSelectQuestion.value : [];
        let allSelectedOptionIds = currentlySelectedSmartSelectOptions.map(v => v.id);
        let visibleSelectedOptions = smartSelectItems.filter(option => allSelectedOptionIds.includes(option.id));
        let visibleUnselectedOptions = smartSelectItems.filter(option => !allSelectedOptionIds.includes(option.id));

        function compareByTitle(a, b) {
            if (a.title < b.title) {
                return -1;
            } else if (a.title > b.title) {
                return 1;
            }
            return 0;
        }

        visibleSelectedOptions = visibleSelectedOptions.sort(compareByTitle);
        visibleUnselectedOptions = visibleUnselectedOptions.sort(compareByTitle);

        setSelectedItems(visibleSelectedOptions);
        setUnselectedItems(visibleUnselectedOptions);
    }

    function getSmartSelectQuestion() {
        let allQuestions = [];
        form.steps.forEach(step =>
            allQuestions = allQuestions.concat(step.questions)
        );

        return allQuestions.find(question => {
            return question.id === smartSelectId
        });
    }

    function valueClicked(smartSelectItem) {
        smartSelectQuestion.value = smartSelectQuestion.value ? smartSelectQuestion.value : [];
        const previouslySelectedItem = smartSelectQuestion.value.find(selectedValueObj => selectedValueObj.id === smartSelectItem.id)
        if (previouslySelectedItem) {
            smartSelectQuestion.value.splice(smartSelectQuestion.value.indexOf(previouslySelectedItem), 1)
        } else {
            smartSelectQuestion.value.push(smartSelectItem);
        }

        AppStorage.setInspectionForm(form);
        createSmartSelectItems(unselectedItems.concat(selectedItems));
    }

    function onCreateNewOptionClicked() {
        history.push({
            pathname: "/inspection/smartselect/" + smartSelectQuestion.id + "/create"
        });
    }

    function goToPreviousPage() {
        history.goBack();
    }
}

function SearchView(props) {
    return <div key="search-input" className="smart-select-search row with-margin">
        <Icon size={20} icon="search" className={'icon'}/>
        <input type="text" className="form-field-input text"
               placeholder="Search"
               autoFocus
               defaultValue={props.defaultValue}
               onChange={props.onChange}/>
    </div>;
}

function ListView(props) {
    return <SmartSelectContent showHint={props.searchQuery.length > 0 && props.searchQuery.length < 3}
                               titlePlural={props.titlePlural}
                               isLoading={props.isLoading}
                               selectedItems={props.selectedItems}
                               unselectedItems={props.unselectedItems}
                               canCreateNewOption={props.canCreate}
                               onCreateClick={props.onCreateClick}
                               onItemClick={props.onItemClick}/>;
}

export default SmartSelect