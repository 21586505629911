import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import "./sass/main.scss";
import Home from "./home/Home";
import Login from "./login/Login";
import Dashboard from "./dashboard/Dashboard";
import Inspection from "./inspection/Inspection";
import SmartSelect from "./smartselect/SmartSelect";
import SmartSelectCreateOption from "./smartselect/smartselect-createoption/SmartSelectCreateOption";

function App() {
    return (
        <Router>
            <link rel="stylesheet"
                  href="https://fonts.googleapis.com/css?family=Roboto:regular,bold,italic|PT+Sans+Narrow:regular,bold">
            </link>
            <div className="App">
                <Switch>
                    <Route path="/login" exact component={Login}/>
                    <Route path="/dashboard" exact component={Dashboard}/>
                    <Route path="/inspection" exact component={Inspection}/>
                    <Route path="/inspection/smartselect/:id/create" component={SmartSelectCreateOption}/>
                    <Route path="/inspection/smartselect/:id" component={SmartSelect}/>
                    <Route path="/" component={Home}/>
                </Switch>
            </div>
        </Router>
    );
}

export default App;
