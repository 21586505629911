import React from "react";
import Icon from "../util/Icon";
import './home.scss'
import {Link} from "react-router-dom";
import LogoHeader from "../components/logoheader/LogoHeader";
import DownloadAppHint from "../components/downloadapphint/DownloadAppHint";

function Home() {

    return (
        <div id="home" className="main">
            <div className="page-content row with-margin">
                <LogoHeader/>
                <DownloadAppHint/>
                <div className="button-footer">
                    <Link to={'/login'}>
                        <div className="button">
                            <div className="text roboto-regular">Continue using web version</div>
                            <Icon size={14} icon="arrow-right" className={'icon'}/>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Home;