import React, {useState} from "react";
import './login.scss'
import LogoHeader from "../components/logoheader/LogoHeader";
import {useForm} from 'react-hook-form';
import Api from "../util/Api";
import AppStorage from "../util/AppStorage";
import ValidationError from "../util/ValidationError";
import {Redirect} from "react-router-dom";
import Popup from "../components/popup/Popup";
import LoadingIndicator from "../components/loadingindicator/LoadingIndicator";

function Login(props) {
    const {register, handleSubmit} = useForm();
    const [isLoading, setIsLoading] = useState(false);

    if (AppStorage.isUserLoggedIn()) {
        return (
            <Redirect to={'/inspection'}/>
        );
    }

    return (
        <div id="login" className="main">

            <LoadingIndicator isLoading={isLoading} indicatorColor={"light"} showBackgroundOverlay={true} isFullscreen={true}/>

            <div className="page-content row with-margin">
                <LogoHeader/>

                <form className="form" onSubmit={handleSubmit(createAppUserIfNeededAndLogin)}>
                    <div className="field">
                        <label htmlFor="email">Email</label>
                        <input type="email" name="email" ref={register} required/>
                    </div>
                    <div className="field">
                        <label htmlFor="password">Password</label>
                        <input type="password" name="password" ref={register} required/>
                    </div>

                    <div className="footer">
                        <input className="button" type="submit" value="Login"/>
                        <div className="forgot-password-link">
                            <a href={process.env.REACT_APP_FORGOT_PASSWORD_URL}>Forgot password?</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );

    function createAppUserIfNeededAndLogin(loginCredentials) {
        if (AppStorage.isAppGUIDRegistered()) {
            login(loginCredentials)
        } else {
            createAppUser(loginCredentials)
        }
    }

    function createAppUser(loginCredentials) {
        let newAppGUID = AppStorage.generateAppGUID();

        function validator(response) {
        }

        function onSuccess(response) {
            setIsLoading(false);
            AppStorage.setRegisteredAppGUID(newAppGUID);
            createAppUserIfNeededAndLogin(loginCredentials);
        }

        function onFailure(error) {
            setIsLoading(false);
            Popup.showDefaultRequestError(function () {
                createAppUser(loginCredentials)
            })
        }

        const config = {
            data: {
                "firmware": "N/A",
                "phoneType": "Web",
                "appGUID": newAppGUID,
                "appVersionCode": process.env.REACT_APP_APP_VERSION,
                "appVersionName": process.env.REACT_APP_APP_VERSION_NAME
            }
        };

        setIsLoading(true);
        Api.post('/appuser', validator, onSuccess, onFailure, config);
    }

    function login(loginCredentials) {

        function validator(response) {
            if (!response.data.accessToken) {
                throw new ValidationError("No access token found.")
            }
        }

        function onSuccess(response) {
            setIsLoading(false)
            AppStorage.setAccessToken(response.data.accessToken);
            props.history.push("/dashboard");
        }

        function onFailure(error) {
            setIsLoading(false)
            if (error.response.status === 401) {
                Popup.show({
                    title: "Login failed",
                    text: "Please check your credentials.",
                    confirmButtonText: "OK"
                })
            } else {
                Popup.showDefaultRequestError(function () {
                    login(loginCredentials)
                })
            }
        }

        let config = {
            data: {
                "username": loginCredentials.email,
                "password": loginCredentials.password
            }
        };

        setIsLoading(true)
        Api.post('/login', validator, onSuccess, onFailure, config);
    }
}

export default Login;