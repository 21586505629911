import React from "react";
import './logoheader.scss'

function LogoHeader() {
    return (
        <div className="logo-header">
            <div className="logo-container">
                <div className="logo-row">
                    <img alt="Impel" className="circle-logo" src={require('../../resources/impel-circle.png')}/>
                    <img alt="Sweap" className="circle-logo" src={require('../../resources/sweap-circle.png')}/>
                    <img alt="Life" className="circle-logo" src={require('../../resources/life-circle.png')}/>
                </div>
            </div>

            <div className="title-wrapper">
                <span className="title">SWEAP</span>
                <span className="subtitle">Inspection Form</span>
            </div>
        </div>
    );
}

export default LogoHeader;