import React from "react";
import './dashboard.scss'
import AppStorage from "../util/AppStorage";
import {Link, Redirect} from "react-router-dom";
import Header from "../inspection/header/Header";
import DownloadAppHint from "../components/downloadapphint/DownloadAppHint";
import AppFeatureList from "../components/appfeaturelist/AppFeatureList";

function Dashboard() {

    if (!AppStorage.isUserLoggedIn()) {
        return (
            <Redirect to={'/login'}/>
        );
    }

    return (
        <div id="dashboard" className="main">
            <Header title="Inspections"/>
            <div className="start-button">
                <Link to={'/inspection'} className="start-link" onClick={() => {
                    AppStorage.setCurrentStepIndex(null);
                    AppStorage.setInspectionForm(null);
                }}>
                    <div className="button">Start inspection</div>
                </Link>
            </div>
            <div className={"row with-margin"}>
                <DownloadAppHint/>
            </div>
            <AppFeatureList/>
            <div className="logout-button-footer">
                <Link to={'/home'} className="logout-link" onClick={() => AppStorage.logout()}>
                    Log out
                </Link>
            </div>
        </div>
    );
}

export default Dashboard;